<template>
  <div
    :class="$style.root"
    ref="rootElement"
    @click="setFocus(true)"
    @mouseenter="setFocusDebounded(true)"
    @mouseleave="setFocusDebounded(false)"
    :data-focus="focus ? '' : null"
  >
    <div :class="$style.content">
      <slot />
    </div>
  </div>
</template>

<script setup>
const rootElement = ref(null)
const focus = ref(false)

const setFocus = (bool) => {
  if (bool) {
    focus.value = true
  } else {
    focus.value = false
  }
}

const setFocusDebounded = useDebounceFn((bool) => { setFocus(bool)}, 2500)
</script>

<style module>
.root {
  position: relative;
}

.root[data-focus] {
  outline: none;
}

.content  {
  transition: filter 0.25s ease;
}

.content * {
  pointer-events: none;
}

.root[data-focus] .content * {
  pointer-events: all;
}

.root:not([data-focus]) .content {
  filter: grayscale(.5);
}
</style>
